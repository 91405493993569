import { isNull, isString, reduce, split, toUpper } from 'lodash'

export const currency = (x: number | string) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(isString(x) ? parseFloat(x) : x)
// this works by coercing input to number by marking it positive, which results in NaN for non-numeric things (except null)
export const isNumeric = (x: number | string | null) => !isNull(x) && +x === +x
export const initials = (str?: string | null, separator?: string) =>
  reduce(
    split(str, separator),
    (acc, word) => (!isNumeric(word) ? `${acc}${toUpper(word.charAt(0))}` : `${acc}${word}`),
    ''
  )
