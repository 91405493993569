import logoImg from '@app/assets/full_logo.svg'
import { initials } from '@app/lib'
import { useSugarContext } from '@app/providers'
import {
  Anchor,
  AppShell,
  Avatar,
  Burger,
  Container,
  createStyles,
  Drawer,
  Group,
  Header,
  Menu,
  NavLink,
  rem,
  Title
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { compact, map, some } from 'lodash'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { signOut } from 'next-auth/react'
import { type FC, type ReactNode, useCallback, useMemo } from 'react'

const headerHeight = rem(70)
const useStyles = createStyles((theme) => ({
  burger: {
    [theme.fn.largerThan('md')]: {
      display: 'none'
    }
  },
  drawer: {
    [theme.fn.largerThan('md')]: {
      display: 'none'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },
  links: {
    [theme.fn.smallerThan('md')]: {
      display: 'none'
    }
  },
  menu: {
    [theme.fn.smallerThan('md')]: {
      display: 'none'
    }
  },
  menuItemLabel: {
    fontSize: 16,
    fontWeight: 500
  },
  navLink: {
    cursor: 'pointer'
  },
  navLinkLabel: {
    fontSize: 18
  }
}))

type NavItemBase = {
  label: string
  href: string
}

type NavItem = NavItemBase & {
  children?: NavItem[]
}

export interface AppLayoutProps {
  buttons?: ReactNode
  children: ReactNode
  title?: string
}

export const AppLayout: FC<AppLayoutProps> = ({ buttons, children, title }) => {
  const [showDrawer, drawerHandler] = useDisclosure(false)
  const sugar = useSugarContext()
  const router = useRouter()
  const { classes } = useStyles()
  const navItems = useMemo<NavItem[]>(
    () =>
      compact([
        sugar?.permissions?.showDocuments
          ? {
              href: '/documents',
              label: 'Documents'
            }
          : null,
        sugar?.permissions?.showExpenditureReporting
          ? {
              href: '#',
              label: 'Reporting',
              children: [
                {
                  href: '/expenditure-reports',
                  label: 'Expenditure Reports'
                },
                {
                  href: '/payment-reports',
                  label: 'Payment Reports'
                }
              ]
            }
          : {
              href: '/payment-reports',
              label: 'Payment Reports'
            },
        sugar?.permissions?.showPaymentSetup
          ? {
              href: '/payment-setup',
              label: 'Payment Setup'
            }
          : null,
        {
          href: process.env.NEXT_PUBLIC_SUPPORT_URL,
          label: 'Support'
        }
      ]),
    [sugar?.permissions]
  )
  const onSignOut = useCallback(async () => {
    await signOut({
      redirect: false
    })

    drawerHandler.close()

    window.location.replace(process.env.NEXT_PUBLIC_LOGOUT_URL)
  }, [drawerHandler])

  return (
    <AppShell
      header={
        <Header height={headerHeight}>
          <Container
            className={classes.header}
            size='xl'
          >
            <Link href='/'>
              <Image
                alt='logo'
                height={50}
                src={logoImg}
                priority
              />
            </Link>
            <Group
              align='center'
              className={classes.links}
              noWrap
              position='center'
              spacing='xl'
            >
              {map(navItems, (navItem, navItemIdx) =>
                navItem.children ? (
                  <Menu key={`anchor-${navItemIdx}`}>
                    <Menu.Target>
                      <Anchor
                        className={classes.navLink}
                        color='navy'
                        size='md'
                        weight='600'
                      >
                        {navItem.label}
                      </Anchor>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {map(navItem.children, (child, childIdx) => (
                        <Menu.Item
                          key={`anchor-${navItemIdx}-${childIdx}`}
                          component={Link}
                          href={child.href}
                        >
                          {child.label}
                        </Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                ) : (
                  <Anchor
                    className={classes.navLink}
                    color='navy'
                    component={Link}
                    href={navItem.href}
                    key={`anchor-${navItemIdx}`}
                    size='md'
                    weight='600'
                  >
                    {navItem.label}
                  </Anchor>
                )
              )}
            </Group>
            <div className={classes.menu}>
              <Menu
                classNames={{
                  itemLabel: classes.menuItemLabel
                }}
                offset={0}
                position='bottom'
                transitionProps={{
                  transition: 'pop',
                  duration: 200
                }}
                trigger='hover'
                withArrow
              >
                <Menu.Target>
                  <Avatar
                    color='navy'
                    radius='xl'
                    title={`${sugar?.profile?.firstName} ${sugar?.profile?.lastName}`}
                    variant='filled'
                  >
                    {sugar?.profile?.id && initials(`${sugar?.profile?.firstName} ${sugar?.profile?.lastName}`, ' ')}
                  </Avatar>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    component={Link}
                    href='/profile'
                  >
                    My Profile
                  </Menu.Item>
                  <Menu.Item onClick={onSignOut}>Sign Out</Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </div>
            <Burger
              opened={showDrawer}
              onClick={drawerHandler.toggle}
              className={classes.burger}
              size='sm'
            />
          </Container>
        </Header>
      }
      padding='lg'
    >
      <Head>
        <title>{`CODEC${title ? ` / ${title}` : null}`}</title>
      </Head>
      <Container
        px={0}
        size='xl'
      >
        <Group
          align='center'
          mb='lg'
          noWrap
          position='apart'
        >
          <Title
            color='white'
            mb={0}
            order={1}
          >
            {title}
          </Title>
          {buttons}
        </Group>
        {children}
      </Container>
      <Drawer
        className={classes.drawer}
        styles={{
          inner: {
            marginTop: headerHeight
          }
        }}
        opened={showDrawer}
        onClose={drawerHandler.close}
        position='right'
        size='100%'
        withCloseButton={false}
        withOverlay={false}
      >
        <NavLink
          classNames={{
            label: classes.navLinkLabel
          }}
          color='navy'
          component={Link}
          href='/dashboard'
          label='Dashboard'
          onClick={drawerHandler.close}
          variant='filled'
        />
        {map(navItems, (link, linkIdx) =>
          link.children?.length ? (
            <NavLink
              active={router.route === link.href}
              classNames={{
                label: classes.navLinkLabel
              }}
              color='navy'
              key={`nav-link-${linkIdx}`}
              opened={some(link.children, { href: router.route })}
              label={link.label}
              variant='filled'
            >
              {map(link.children, (child, childIdx) => (
                <NavLink
                  active={router.route === child.href}
                  classNames={{
                    label: classes.navLinkLabel
                  }}
                  color='navy'
                  component={Link}
                  href={child.href as string}
                  key={`nav-link-${linkIdx}-${childIdx}`}
                  label={child.label}
                  onClick={drawerHandler.close}
                  variant='filled'
                />
              ))}
            </NavLink>
          ) : (
            <NavLink
              active={router.route === link.href}
              classNames={{
                label: classes.navLinkLabel
              }}
              color='navy'
              component={Link}
              href={link.href as string}
              key={`nav-link-${linkIdx}`}
              label={link.label}
              onClick={drawerHandler.close}
              variant='filled'
            />
          )
        )}
        <NavLink
          classNames={{
            label: classes.navLinkLabel
          }}
          color='navy'
          label='Sign Out'
          onClick={onSignOut}
          variant='filled'
        />
      </Drawer>
    </AppShell>
  )
}
