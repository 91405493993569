import { AppLayout } from '@app/components'
import { Paper, Text } from '@mantine/core'
import { type NextPage } from 'next'

const NotFoundPage: NextPage = () => (
  <AppLayout title='Not Found'>
    <Paper p='xl'>
      <Text>
        Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another
        URL.
      </Text>
    </Paper>
  </AppLayout>
)

export default NotFoundPage
